<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="primary"
          icon="mdi-account-tie"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light my-2">
              {{ partner.name }}
            </div>
          </template>
          <v-row>
            <v-col cols="4">
              <v-icon>mdi-phone</v-icon>
              <span>
                {{ partner.phoneNumber }}
              </span>
            </v-col>
            <v-col cols="4">
              <v-icon>mdi-email</v-icon>
              <span>
                {{ partner.eMail }}
              </span>
            </v-col>
            <v-col cols="4">
              <v-icon>mdi-account-box</v-icon>
              <span>
                {{ partner.partnerType }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-icon>mdi-city</v-icon>
              <span>
                {{ partner.addressLine1 }}; {{ partner.addressLine2 }}; {{ partner.city }} {{ partner.stateCode }}, {{ partner.postalCode }}
              </span>
            </v-col>
            <v-col cols="6">
              <v-icon>mdi-map-marker</v-icon>
              <span>
                {{ partner.stateCode }}, {{ partner.countryCode }}
              </span>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <productListings :partner="partnerId" />
        <productLinks :partner="partnerId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import partnerAPI from '@/services/partnerServices'
  import productLinks from '@/views/components/products/ProductLinks'
  import productListings from '@/views/components/products/ProductListings'

  export default {
    Name: 'PartnerDetail',
    components: {
      productListings,
      productLinks,
    },
    props: {
      partnerId: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return {
        partner: undefined,
      }
    },
    created: function () {
      partnerAPI.getPartnerById(this.partnerId)
        .then(response => {
          this.partner = response.data
        })
    },
  }
</script>
