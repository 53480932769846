var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"color":"secondary","icon":"mdi-link-variant"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light my-2"},[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.addEnabled)?_c('v-btn',_vm._g({attrs:{"absolute":"","dark":"","fab":"","top":"","right":"","small":"","color":"indigo"},on:{"click":_vm.onAddNewLink}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])})],1)]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('dataTableSearch'),"hide-details":"","single-line":""},model:{value:(_vm.link_search),callback:function ($$v) {_vm.link_search=$$v},expression:"link_search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.linkList,"item-key":"productId","search":_vm.link_search,"sort-by":[],"sort-desc":[],"multi-sort":""},on:{"update:search":function($event){_vm.link_search=$event}},scopedSlots:_vm._u([{key:"item.input",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.owningPartnerId)+" - "+_vm._s(item.inProductId)+" ")]}},{key:"item.conversion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.conversionRatio)+" ")]}},{key:"item.output",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.receivingPartnerId)+" - "+_vm._s(item.outProductId)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.onLinkEdit(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.onLinkDelete(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }