<template>
  <base-material-card
    color="secondary"
    icon="mdi-link-variant"
  >
    <template
      v-slot:after-heading
    >
      <div class="display-2 font-weight-light my-2">
        {{ title }}
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="addEnabled"
              absolute
              dark
              fab
              top
              right
              small
              color="indigo"
              v-on="on"
              @click="onAddNewLink"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </div>
    </template>

    <v-text-field
      v-model="link_search"
      append-icon="mdi-magnify"
      class="ml-auto"
      :label="$t('dataTableSearch')"
      hide-details
      single-line
      style="max-width: 250px;"
    />

    <v-divider class="mt-3" />

    <v-data-table
      :headers="headers"
      :items="linkList"
      item-key="productId"
      :search.sync="link_search"
      :sort-by="[]"
      :sort-desc="[]"
      multi-sort
    >
      <template v-slot:item.input="{ item }">
        {{ item.owningPartnerId }} - {{ item.inProductId }}
      </template>
      <template v-slot:item.conversion="{ item }">
        {{ item.conversionRatio }}
      </template>
      <template v-slot:item.output="{ item }">
        {{ item.receivingPartnerId }} - {{ item.outProductId }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          depressed
          class="mr-2"
          color="success"
          @click="onLinkEdit(item)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          outlined
          small
          color="error"
          @click="onLinkDelete(item)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
  import productAPI from '@/services/productServices'

  export default {
    Name: 'ProductLinks',
    props: {
      product: {
        type: String,
        default: '',
      },
      partner: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      addEnabled: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        linkList: [],
        headers: [
          {
            text: this.$t('colLinkIn'),
            value: 'input',
          },
          {
            text: this.$t('colLinkConversion'),
            value: 'conversionRatio',
          },
          {
            text: this.$t('colLinkOut'),
            value: 'output',
          },
          {
            text: this.$t('colActions'),
            value: 'actions',
          },
        ],
        link_search: '',
      }
    },
    created: function () {
      if (this.product === '' && this.partner === '') {
        productAPI.getAllProductLinks()
          .then(response => {
            this.linkList = response.data
          })
          .catch(error => {
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      } else if (this.product !== '' && this.partner === '') {
        productAPI.getProductLinksByProduct(this.product)
          .then(response => {
            this.linkList = response.data
          })
          .catch(error => {
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      } else if (this.product === '' && this.partner !== '') {
        productAPI.getProductLinksByPartner(this.partner)
          .then(response => {
            this.linkList = response.data
          })
          .catch(error => {
            this.errorMessage = error.errorMessage
            this.showErrorDialog = true
          })
      }
    },
    methods: {
      onAddNewLink () {
        this.$router.push('/settings/addLink')
      },
      onLinkEdit (item) {
        this.$router.push(`editLink/${item.linkID}`)
      },
      onLinkDelete (item) {
        const msg = `${this.$t('confirmLinkDelete')} ${item.inProductId} - ${item.outProductId}\n${this.$t('confirmContinue')}`
        if (confirm(msg)) {
          this.modelProcessing = true
          productAPI.deleteProductLink(item.linkID)
            .then(repsonse => {
              alert(this.$t('alertLinkDelete'))
              this.modelProcessing = false
            })
            .catch(error => {
              this.modelProcessing = false
              this.errorMessage = error.errorMessage
              this.showErrorDialog = true
            })
        }
      },
    },
  }
</script>
